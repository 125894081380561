<template>
  <div class="appeal">
    <div class="container">
      <div class="contacts__nav">
        <locale-router-link to="/" class="crumbs-link main-page-link">
          {{ $t("home") }}</locale-router-link
        >
        <locale-router-link to="/contacts" class="crumbs-link main-page-link">
          {{ $t("contacts") }}
        </locale-router-link>

        <a class="crumbs-link contacts-link">{{ $t("appealForm.title") }}</a>
      </div>
      <AppModalAppeal v-if="topic" :topic-title="topic" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { getNotificationEmailGroups } from "@/api/notification-email-groups";
export default {
  name: "ModalAppealPage",
  components: {
    AppModalAppeal: () => import("@/components/modal/AppModalAppeal.vue"),
  },
  data() {
    return {
      topic: null,
    };
  },
  computed: {
    ...mapState("contactsModule", ["emailGroups"]),
  },
  created() {
    if (!this.emailGroups.length) {
      getNotificationEmailGroups().then((res) => {
        this.topic = res.find((e) => e.id == this.$route.params.id);
      });
    } else {
      this.topic = this.emailGroups.find((e) => e.id == this.$route.params.id);
    }
  },
};
</script>

<style lang="scss">
.appeal {
  padding: 30px 0;
  .modal {
    position: static;
    z-index: 10;
    padding: 0;
    background: none;
    min-height: auto;
  }

  .club-registration-modal {
    box-shadow: none;
    padding: 0;
    margin-top: 20px;
  }

  .club-registration-modal__title {
    text-align: left;
  }
  .club-registration-modal__close {
    display: none;
  }
  .club-registration-modal__goal {
    margin-right: 0;
  }
  .club-registration-modal.appeal-modal
    .club-registration-modal__wrapper.is-desktop {
    max-height: 100%;
  }
}
</style>
